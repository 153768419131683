import React, { forwardRef } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './styles.module.scss';

export const FormField = ({ column, name, item, menuPlacement, index }) => {
  const { control, watch } = useFormContext();
  const processed = watch(`records[${index}].processed`);
  const notificationType = watch(`records[${index}].notification`);

  const {
    field,
    fieldState,
    formState,
  } = useController({
    name,
    control,
  });

  return column.Field({
    field,
    fieldState,
    formState,
    item,
    isDisabled: Boolean(processed),
    notificationType,
    menuPlacement,
  });
};

FormField.propTypes = {
  column: PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    Field: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  item: PropTypes.shape({}),
};

FormField.defaultProps = {
  item: null,
};

export const ListItem = forwardRef((props, ref) => {
  const { field: item, index: listItemIndex, defaultCounter, columns, allItemsCount } = props;
  const isLastItems = (allItemsCount - 6) > listItemIndex;
  return (
    <div
      data-id="list-item"
      ref={ref}
      className={
        classnames(
          styles.listItem,
          {
            [styles.elevator]: listItemIndex >= defaultCounter,
          }
        )
      }
    >
      {columns.map((column, index) => {
        const name = `records[${listItemIndex}].${column.accessor}`;
        const key = `${name}-${index}`;

        if (column.type === 'text') {
          return column.Field({
            key,
            data: item[column.accessor],
            item,
          });
        }
        return (
          <FormField
            key={key}
            column={column}
            name={name}
            item={item}
            index={listItemIndex}
            menuPlacement={isLastItems ? 'auto' : 'top'}
          />
        );
      })}
    </div>
  );
});

ListItem.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  defaultCounter: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allItemsCount: PropTypes.number.isRequired,
};
