import update from 'immutability-helper';
import { get } from 'lodash';
import moment from 'moment';
import { createReducer } from 'redux-create-reducer';

import { employeesReviewsActionsTypes } from './actions';

import { ADDITIONAL_COLUMNS_INITIAL_STATE, CONTROL_KEYS, DEFAULT_ORDER, ENTITY_NAME, STORE_KEYS } from './constants';

const defaultFiltersState = {
  [STORE_KEYS.DATE_FROM]: null,
  [STORE_KEYS.DATE_TO]: null,
  [STORE_KEYS.BY_EVALUATION_DATE]: false,
};
const initialState = {
  entityName: ENTITY_NAME,
  errors: {},
  isFiltering: false,
  isFormSubmitted: false,
  isFetchingReviews: false,
  isFetchingCommonReviewsData: false,
  orderRules: {
    reviews: {
      orderBy: DEFAULT_ORDER.REVIEWS_TABLE,
    },
  },
  filters: defaultFiltersState,
  reviewReportCommonData: {},
  additionalColumns: ADDITIONAL_COLUMNS_INITIAL_STATE,
  reviews: [],
  otherReviews: [],
  notificationsLog: [],
  employeeReviews: [],
  employeesList: [],
  projectsList: [],
  selectedDate: moment(),
  selectedProjects: [],
  selectedEmployee: null,
  controls: {
    [CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]: false,
    [CONTROL_KEYS.RATING_LESS_THAN_THREE]: false,
  },
};

export default createReducer(initialState, {
  [employeesReviewsActionsTypes.SET_CONTROLS](state, { payload }) {
    return update(state, {
      controls: { $merge: payload },
    });
  },
  [employeesReviewsActionsTypes.NOTIFY_PROJECT_LEAD_SUCCESS](state, { payload }) {
    return update(state, {
      errors: {
        $unset: ['notifyProjectLeadError'],
      },
      notificationsLog: { $push: [payload] },
    });
  },
  [employeesReviewsActionsTypes.NOTIFY_PROJECT_LEAD_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.RESET_REVIEW_REPORT](state) {
    return update(state, {
      employeeReviews: { $set: [] },
      filters: { $set: defaultFiltersState },
      reviewReportCommonData: {
        $set: {},
      },
    });
  },

  [employeesReviewsActionsTypes.SEND_PDF_REVIEWS](state) {
    return update(state, {
      isFormSubmitted: { $set: true },
    });
  },
  [employeesReviewsActionsTypes.SEND_PDF_REVIEWS_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['sendPdfReviewsError'],
      },
      isFormSubmitted: { $set: false },
    });
  },
  [employeesReviewsActionsTypes.SEND_PDF_REVIEWS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: { $set: false },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.CHANGE_REVIEW_REPORT_FILTER](state, {
    payload,
  }) {
    return update(state, {
      filters: { $merge: payload },
    });
  },

  [employeesReviewsActionsTypes.SET_ORDER_RULES](state, {
    payload: {
      tableName,
      orderRules,
    },
  }) {
    return update(state, {
      orderRules: {
        [tableName]: {
          $set: orderRules,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.SELECT_EMPLOYEE](state, { payload: { employeeId } }) {
    return update(state, {
      isFetchingReviews: { $set: true },
      selectedEmployee: { $set: employeeId },
      employeeReviews: { $set: [] },
    });
  },
  [employeesReviewsActionsTypes.SELECT_EMPLOYEE_SUCCESS](state, {
    payload: {
      lastReviewDate,
      employeeReviews,
      firstReviewDate,
      lastEvaluationDate,
    },
  }) {
    return update(state, {
      isFetchingReviews: { $set: false },
      employeeReviews: { $set: employeeReviews },
      filters: { $merge: {
        [STORE_KEYS.DATE_FROM]: firstReviewDate ? moment(firstReviewDate) : null,
        [STORE_KEYS.DATE_TO]: lastReviewDate ? moment(lastReviewDate) : null,
        [STORE_KEYS.BY_EVALUATION_DATE]: false,
      } },
      reviewReportCommonData: {
        $set: {
          lastReviewDate: lastReviewDate ? moment(lastReviewDate) : null,
          firstReviewDate: firstReviewDate ? moment(firstReviewDate) : null,
          lastEvaluationDate: lastEvaluationDate ? moment(lastEvaluationDate) : null,
        },
      },
      errors: {
        $unset: ['selectEmployeeError'],
      },
    });
  },
  [employeesReviewsActionsTypes.SELECT_EMPLOYEE_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFetchingReviews: { $set: false },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.UPDATE_COLUMNS_STATE](state, { payload }) {
    const initialOrder = initialState.orderRules;
    return update(state, {
      additionalColumns: { $merge: payload },
      orderRules: { $set: initialOrder },
    });
  },

  [employeesReviewsActionsTypes.SUBMIT_REVIEWS](state) {
    return update(state, {
      isFormSubmitted: { $set: true },
    });
  },
  [employeesReviewsActionsTypes.SUBMIT_REVIEWS_SUCCESS](state) {
    return update(state, {
      errors: {
        $unset: ['submitReviewsError'],
      },
      isFormSubmitted: { $set: false },
    });
  },
  [employeesReviewsActionsTypes.SUBMIT_REVIEWS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFormSubmitted: { $set: false },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA](state) {
    return update(state, {
      isFetchingCommonReviewsData: {
        $set: true,
      },
    });
  },

  [employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA_SUCCESS](state, {
    payload: {
      employeesList,
      projectsList,
    },
  }) {
    return update(state, {
      errors: {
        $unset: ['getReviewsCommonDataError'],
      },
      employeesList: { $set: employeesList },
      projectsList: { $set: projectsList },
      isFetchingCommonReviewsData: { $set: false },
    });
  },

  [employeesReviewsActionsTypes.GET_REVIEWS_COMMON_DATA_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFetchingReviews: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.SET_DATE](state, { payload: { selectedDate } }) {
    return update(state, {
      selectedDate: { $set: selectedDate },
      reviews: { $set: [] },
      otherReviews: { $set: [] },
      notificationsLog: { $set: [] },
      isFetchingReviews: {
        $set: true,
      },
      controls: {
        [CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]: {
          $set: false,
        },
        [CONTROL_KEYS.RATING_LESS_THAN_THREE]: {
          $set: false,
        },
      },
    });
  },

  [employeesReviewsActionsTypes.SELECT_PROJECTS](state, { payload: { projects } }) {
    return update(state, {
      selectedProjects: { $set: projects },
    });
  },

  [employeesReviewsActionsTypes.GET_REVIEWS](state) {
    return update(state, {
      reviews: { $set: [] },
      otherReviews: { $set: [] },
      notificationsLog: { $set: [] },
      isFetchingReviews: {
        $set: true,
      },
    });
  },
  [employeesReviewsActionsTypes.GET_REVIEWS_SUCCESS](state, {
    payload: {
      reviews: reviewsList,
      otherReviews,
      notificationsLog,
    },
  }) {
    return update(state, {
      errors: {
        $unset: ['getReviewsError'],
      },
      reviews: { $set: reviewsList },
      otherReviews: { $set: otherReviews },
      notificationsLog: { $set: notificationsLog },
      isFetchingReviews: {
        $set: false,
      },
      controls: {
        [CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]: {
          $set: false,
        },
      },
    });
  },
  [employeesReviewsActionsTypes.GET_REVIEWS_FAIL](state, {
    payload: {
      error,
    },
  }) {
    const errors = get(state, 'errors', {});

    return update(state, {
      isFetchingReviews: {
        $set: false,
      },
      errors: {
        $set: {
          ...errors,
          ...error,
        },
      },
    });
  },
});
