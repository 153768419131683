import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { SELECT_ACTIONS } from 'components/form-controls/constants';
import ErrorMessage from 'elements/error-message';
import CustomLink from 'elements/link';
import { isEqual, get, isEmpty } from 'lodash';
import ReactSelect, { components, Creatable } from 'react-select';
import styled, { css } from 'styled-components';

import './styles.scss';

const getInput = (inputProps) => {
  const { autoComplete, type, ...rest } = inputProps;
  return (
    <components.Input
      autoComplete="new-password"
      // type="text"
      {...rest}
    />
  );
};

const getMenuList = (menuListProps) => {
  const { selectProps: { isMulti } } = menuListProps;

  return (
    <>
      <components.MenuList
        {...menuListProps}
      >
        {
          React.Children.map(menuListProps.children, (child) => ({
            ...child,
            props: {
              ...child.props,
              innerProps: {
                ...child.props.innerProps,
                title: get(child, 'props.data.label', ''),
              },
            },
          }))
        }
      </components.MenuList>

      {
        isMulti && (
          <button
            className="button button--cancel select-item__button"
            onClick={() => window.document.activeElement.blur()}
            tabIndex={-1}
          >
            Ok
          </button>
        )
      }
    </>

  );
};

const getPlaceholder = (placeholderProps) => {
  const { selectProps: { required } } = placeholderProps;

  return (
    <components.Placeholder
      {...placeholderProps}
    >
      {placeholderProps.children}

      {
        !required && (
          <span className="text-input__optional-label">
            &nbsp;(optional)
          </span>
        )
      }
    </components.Placeholder>
  );
};

const getControl = (controlProps) => {
  const { hasValue, menuIsOpen, selectProps: { placeholder, withLabel, hidePlaceholderOnMenuOpen, inputValue } } = controlProps;
  const hidePlaceholder = (hidePlaceholderOnMenuOpen && menuIsOpen) || (hasValue && !isEmpty(inputValue));
  return (
    <components.Control
      {...controlProps}
    >
      {
        controlProps.children
      }

      {
        withLabel && (
          <p
            className={
              classNames(
                'select-item__label',
                { 'select-item__label--hidden': hidePlaceholder }
              )
            }
          >
            {placeholder}
          </p>
        )
      }
    </components.Control>
  );
};

const getSingleValue = (singleValueProps) => {
  const {
    selectProps: {
      isLink,
      path,
      href,
      isSingleValueComponent,
    },
    data,
  } = singleValueProps;

  const singleValue = useMemo(() => {
    if (isSingleValueComponent) {
      return <div title={get(data, 'label', '')}>{singleValueProps.children}</div>;
    }

    return singleValueProps.children;
  }, [
    isSingleValueComponent,
    singleValueProps.children,
    data,
  ]);

  return (
    <components.SingleValue
      title={get(data, 'label', '')}
      {...singleValueProps}
    >
      {
        isLink ? (
          <CustomLink
            className="select-item__link"
            data={singleValueProps.children}
            pathname={path}
            href={href}
            withBlank
          />
        ) : singleValue
      }
    </components.SingleValue>
  );
};

const StyledDiv = styled.div`
  font-size: 1.5rem;

  ${({ cssRules }) => css`${cssRules}`}`;

const getCustomStyles = (styles) => ({
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  }),
  ...styles,
});

const Select = ({
  fieldData: {
    href,
    path,
    isLink,
    selected,
    styles = {},
    optionsFilter,
    items: options,
    hideSelectedOptions = false,
  },
  name,
  error,
  isMulti,
  onChange,
  cssRules,
  isLocked,
  withLabel,
  withTitle,
  withError,
  placeholder,
  isCreatable,
  withWarning,
  withErrorBox,
  menuPosition,
  menuPlacement,
  warningMessage,
  validationRules,
  defaultMenuIsOpen,
  hidePlaceholderOnMenuOpen,
  isSingleValueComponent,
  title,
}) => {
  const customStyles = useMemo(() => getCustomStyles(styles), [styles]);
  const { isRequired, isNumeric } = validationRules;
  const [currentItem, setCurrentItem] = useState(selected || {});

  useEffect(() => {
    if (!isEqual(selected, currentItem)) {
      setCurrentItem(selected);
    }
  }, [selected]);

  const filterOption = ({ label, ...rest }, term) => {
    const subString = String(term).toLowerCase();
    const optionLabel = String(label).toLowerCase();

    return optionsFilter ?
      optionsFilter({ label, term, ...rest }) &&
      optionLabel.includes(subString) :
      optionLabel.includes(subString);
  };

  const updateSelect = (data, selectedItem) => {
    if (data.value === '*') {
      setCurrentItem(null);
      return onChange(null, currentItem);
    }

    if (isMulti) {
      const isExcludeAll = get(selectedItem.option, 'isExcludeAll', false);
      const action = get(selectedItem, 'action', SELECT_ACTIONS.DESELECT_OPTION);

      if (isExcludeAll && action === SELECT_ACTIONS.SELECT_OPTION) {
        const allItems = data.filter(({ label }) => label === 'All');
        setCurrentItem(allItems);
        return onChange(allItems.map(({ value }) => value), currentItem);
      }

      setCurrentItem(currentItem);
      return onChange(data.reduce((currentData, { value }) => value ? [...currentData, value] : currentData, []), currentItem);
    }

    setCurrentItem(data);
    return onChange(isNumeric ? Number(data.value) : data.value);
  };

  const hasItems = !!(options && options.length);
  const getOptionValue = ({ value: optionValue }) => optionValue || '';

  const onCreateOption = (option) => {
    setCurrentItem({
      label: option,
      value: option,
    });
    onChange(option);
  };

  return (
    <StyledDiv
      title={title}
      className={
        classNames(
          'form-select',
          'form-select--new',
          { 'form-select--with-error': withError }
        )
      }
      cssRules={cssRules}
    >
      <div title={withTitle ? get(currentItem, 'label', '') : null}>
        {
          React.createElement(
            isCreatable ?
              Creatable :
              ReactSelect,
            {
              name,
              path,
              href,
              value: currentItem,
              isLink,
              isMulti,
              options,
              withLabel,
              placeholder,
              menuPosition,
              menuPlacement,
              filterOption,
              inputId: name,
              onCreateOption,
              getOptionValue,
              isClearable: false,
              hideSelectedOptions,
              styles: customStyles,
              required: isRequired,
              tabSelectsValue: false,
              onChange: updateSelect,
              defaultMenuIsOpen,
              className: 'select-item',
              closeMenuOnSelect: !isMulti,
              classNamePrefix: 'select-item',
              error: withErrorBox ? '' : error,
              isDisabled: !hasItems || isLocked,
              isSingleValueComponent,
              hidePlaceholderOnMenuOpen,
              components: {
                Placeholder: getPlaceholder,
                Control: getControl,
                SingleValue: getSingleValue,
                MenuList: getMenuList,
                Input: getInput,
              },
            }
          )
        }
      </div>
      {
        withError && hasItems && !isLocked && !withErrorBox && (
          <ErrorMessage
            isVisible={withError}
          >
            {withError ? error : warningMessage}
          </ErrorMessage>
        )
      }

      {
        (!withError && !!withWarning) && hasItems && !isLocked && !withErrorBox && (
          <ErrorMessage
            isVisible={!!warningMessage}
            withIcon
          >
            {withError ? error : warningMessage}
          </ErrorMessage>
        )
      }

    </StyledDiv>
  );
};

Select.propTypes = {
  withErrorBox: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLocked: PropTypes.bool,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  warningMessage: PropTypes.string,
  withWarning: PropTypes.bool,
  error: PropTypes.string,
  withError: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldData: PropTypes.shape({
    isLink: PropTypes.bool,
    path: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
    })),
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ]),
  }),
  cssRules: PropTypes.string,
  withLabel: PropTypes.bool,
  withTitle: PropTypes.bool,
  menuPosition: PropTypes.string,
  menuPlacement: PropTypes.string,
  defaultMenuIsOpen: PropTypes.bool,
  title: PropTypes.string,
  hidePlaceholderOnMenuOpen: PropTypes.bool,
  isSingleValueComponent: PropTypes.bool,
};

Select.defaultProps = {
  isCreatable: false,
  withErrorBox: false,
  isMulti: false,
  styles: {},
  isLocked: false,
  warningMessage: '',
  withWarning: false,
  validationRules: {},
  fieldData: {
    selected: {
      value: '',
      label: '',
    },
    items: [],
    isLink: false,
    path: '',
  },
  onChange: () => null,
  error: '',
  withError: false,
  placeholder: '',
  cssRules: '',
  withLabel: true,
  withTitle: false,
  menuPosition: 'absolute',
  menuPlacement: 'bottom',
  defaultMenuIsOpen: false,
  title: '',
  hidePlaceholderOnMenuOpen: false,
  isSingleValueComponent: false,
};

export default Select;
