import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Dropdown from 'components/dropdown';
import ExpansionPanel from 'components/expansion-panel';
import FileUpload from 'components/file-upload';
import AsyncSelect from 'components/form-controls/form-async-select';
import Calendar from 'components/form-controls/form-calendar';
import Checkbox from 'components/form-controls/form-checkbox';
import MsaTextInput from 'components/form-controls/form-msa-text-input';
import Select from 'components/form-controls/form-select';
import SelectNew from 'components/form-controls/form-select-new';
import Slider from 'components/form-controls/form-slider';
import Switcher from 'components/form-controls/form-switcher';
import TableHeading from 'components/form-controls/form-table-heading';
import Input from 'components/form-controls/form-text-input';
import ActionButton from 'elements/action-button';
import FormFilter from 'elements/form-filter';
import Subheading from 'elements/form-subheading';
import Viewbox from 'elements/form-viewbox';
import CustomLink from 'elements/link';
import OrderButton from 'elements/order-button';
import Text from 'elements/plain-text';
import { get, isEqual } from 'lodash';
import { toast } from 'react-toastify';

import FieldArrayUnit from './field-array-unit';
import MultiLine from './multi-line';

const units = {
  link: CustomLink,
  text: Input,
  select: Select,
  selectNew: SelectNew,
  asyncSelect: AsyncSelect,
  slider: Slider,
  viewBox: Viewbox,
  toggle: Switcher,
  description: Text,
  dropdown: Dropdown,
  checkbox: Checkbox,
  calendar: Calendar,
  subheading: Subheading,
  orderButton: OrderButton,
  actionButton: ActionButton,
  tableHeading: TableHeading,
  msaText: MsaTextInput,
  fileUpload: FileUpload,
  filter: FormFilter,
  empty: () => <div />,
};

const FormUnit = ({ unit, index, form, isVisible }) => {
  const {
    type,
    name,
    label,
    isLocked,
    isHidden,
    validFrom,
    fieldData,
    actionName,
    targetName,
    parentField,
    layoutEffect,
    onFormChange,
    summaryConfig,
    getTargetValue,
    validateOnChange,
    actionArguments = {},
    orderRules,
    filterRules,
    data,
    withFilter,
    intersectionContainer,
    ...rest
  } = unit;

  const {
    values,
    errors,
    touched,
    setFieldValue,
    hasFormChanges,
    setFieldTouched,
    setFieldError,
    actions,
    withErrorBox,
    formId,
    isSubmitting,
  } = form;
  const { isHidden: isUnitHidden, ...unitProps } = onFormChange ? onFormChange(form) : {};
  const isElementRendered = !(isHidden || isUnitHidden);
  const error = get(errors, name);
  const isTouched = !!get(touched, name) || isSubmitting; // TODO need some checks from QA

  const formValue = get(values, name, '');

  const parentFieldsData = parentField ?
    parentField.name.map((parentFieldName) => get(values, parentFieldName)) :
    null;

  if (unit.isFieldArray) {
    const [calculatedValue, setCalculatedValue] = useState(null);
    useEffect(() => {
      if (parentField) {
        const { strictDependence, valueSetter } = parentField;
        const isSomeParentTouched = parentField.name.some((parentFieldName) => get(touched, parentFieldName));
        if (isSomeParentTouched || strictDependence) {
          const { valueGetter } = parentField;
          const updatedValue = valueGetter(values, fieldData);
          if (updatedValue && !isEqual(calculatedValue, updatedValue)) {
            setCalculatedValue(updatedValue);
            if (valueSetter) {
              valueSetter(setFieldValue, updatedValue);
            }
          }
        } else {
          setCalculatedValue(null);
          if (valueSetter) {
            valueSetter(setFieldValue, null);
          }
        }
      }
    }, parentFieldsData, setFieldValue);

    const renderedUnit = React.createElement(
      FieldArrayUnit,
      {
        fieldData,
        name,
        form,
        isVisible,
        layoutEffect,
        validateOnChange,
        parentField,
        parentFieldsData,
        withFilter,
        intersectionContainer,
      },
    );
    return (
      summaryConfig ? (
        <ExpansionPanel
          actions={actions}
          key={name || index}
          summaryConfig={summaryConfig}
        >
          {renderedUnit}
        </ExpansionPanel>
      ) :
        renderedUnit
    );
  }

  if (unit.isMultiple) {
    return isElementRendered ? React.createElement(
      MultiLine,
      {
        unit,
        form,
        isVisible,
      },
    ) : null;
  }

  const onChange = (value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true);
    if (error && !isEqual(value, formValue)) {
      if (withErrorBox) {
        toast.dismiss(name);
      }

      setFieldError(name);
    }
  };

  const onClick = (args = {}) => {
    if (type === 'orderButton') {
      orderRules.orderBy = data;
    }
    const innerActionName = get(args, 'innerActionName', actionName);
    const innerTargetName = get(args, 'innerTargetName', targetName);
    const innerGetTargetValue = get(args, 'innerGetTargetValue', getTargetValue);
    const acceptFunction = () => setFieldValue(innerTargetName, innerGetTargetValue(values));

    if (args.data === 'Clear' && innerTargetName) {
      setFieldError(innerTargetName, undefined);
    }

    if (innerActionName && innerTargetName !== undefined) {
      return actions[innerActionName]({ ...args, ...actionArguments, acceptFunction });
    }

    if (innerActionName) {
      return actions[innerActionName]({ ...args, ...actionArguments, parentFieldsData });
    }

    if (innerTargetName !== undefined) {
      setFieldValue(innerTargetName, innerGetTargetValue(values));
    }

    if (error) {
      setFieldError(innerTargetName);
    }

    return null;
  };

  if ((error && withErrorBox && isSubmitting)) {
    const options = {
      toastId: name,
      containerId: formId,
      closeOnClick: true,
    };

    toast.error(`\u0022${label || name}\u0022 ${error}`, options);
  }

  if (!isElementRendered) {
    return null;
  }

  return (
    React.createElement(
      units[type],
      {
        key: name || index,
        index,
        type,
        name,
        fieldData,
        error,
        withErrorBox,
        isTouched,
        onChange,
        hasFormChanges,
        parentField,
        parentFieldsData,
        touched,
        formValues: values,
        onClick,
        formValue,
        isLocked: !isVisible || isLocked,
        data,
        orderRules,
        actions,
        ...rest,
        ...unitProps,
      },
    )
  );
};

FormUnit.propTypes = {
  unit: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  form: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool,
};

FormUnit.defaultProps = {
  isVisible: true,
  actions: {},
};

export default FormUnit;
