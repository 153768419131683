import gql from 'graphql-tag';

export const queries = {
  getRequiredReviewsReport: gql`
    query reviewsWidgetReport {
      reviewsWidget {
        devstaffId
        projectLeadFullname
        resourceData{
          resId
          resFullname
          internalCategoryName
          missingReviewPeriod
          role
          lastReviewWeek
          previousReviewWeek
        }
      }
    }
  `,
};
