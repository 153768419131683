import { createSelector } from 'reselect';

export const selectErrors = (state) => state.requiredReviewsReport.errors;
export const selectEntityName = (state) => state.requiredReviewsReport.entityName;

export const selectRequiredReviewsReportData = (state) => state.requiredReviewsReport.data || [];
export const selectRequiredReviewsReportFetching = (state) => state.requiredReviewsReport.isFetching;

export const selectProjectLeads = createSelector(
  selectRequiredReviewsReportData,
  (data) => data ? data.map((item) => ({
    value: item.projectLeadFullname,
    label: item.projectLeadFullname,
  })) : []
);
