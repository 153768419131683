import gql from 'graphql-tag';

export default {
  devstaffReviewsReport: gql`
  query devstaffReviewsReport($devstaffId: Int) {
      devstaffReviewsReport(devstaffId: $devstaffId) {
        employeeReviews: reviews {
          year
          week
          rating
          review
          projectName
          reviewBy
          role
          isPl
          isReviewByPl
        }
        firstReviewDate
        lastEvaluationDate
        lastReviewDate
      }
    }
  `,
  generatePDF: gql`
    mutation devstaffReviewPdfReport($fields: DevstaffReviewPdfReportInput!) {
      devstaffReviewPdfReport(fields: $fields) {
        url
      }
    }
  `,
  notifyProjectLead: gql`
    mutation devstaffReviewRequiredNotification($fields: ReviewRequiredNotifyInput!) {
      devstaffReviewRequiredNotification(fields: $fields) {
        ok
        notificationDatetime
      }
    }
  `,
  devstaffReviewSendPdfReport: gql`
    mutation devstaffReviewSendPdfReport(
      $emailFields: DevstaffReviewSendPdfReportInput!
      $pdfFields: DevstaffReviewPdfReportInput!
    ) {
      devstaffReviewSendPdfReport(
        emailFields: $emailFields
        pdfFields: $pdfFields
      ) {
        ok
      }
    }
  `,
  manageReviews: gql`
    mutation manageReviews(
      $week: Int!
      $year: Int!
      $notify: Boolean!
      $deleteReviewsData: [Int]
      $blockReviewsData: [BlockReviewInput]
      $updateReviewsData: [UpdateReviewItem]
    ) {
       manageReviews(
        week: $week
        year: $year
        notify: $notify
        deleteReviewsData: $deleteReviewsData
        blockReviewsData: $blockReviewsData
        updateReviewsData: $updateReviewsData
      ) {
        ok
      }
    }
  `,
  getReviewsCommonData: gql`{
    employeesList: devstaffv2List {
      devstaffId
      fullname
      name
      isDismissed
      isOnLeave
      isIntern
      isNew
    }
    projectsList: allInternalCategoriesForPl {
      value: internalCategoryId
      label: internalCategoryName
    }
  }`,
  getReviews: gql`
    query devstaffReviews($week: Int, $year: Int) {
      notificationsLog: devstaffReviewRequiredNotificationLog(week: $week, year: $year) {
        projectId: jiraProject
        notificationDatetime
      }
      reviews: devstaffReviews(week: $week, year: $year) {
        isPl
        role
        blockReason
        isReviewRequired
        isBlocked
        loggedHrs
        devstaffId
        devstaffName: devstaffFullname
        jiraProject
        jiraProjectName
        clientCategoryName
        internalCategoryName
        clientCategoryId
        internalCategoryId
        defaultRole
        isOldInterface
        plName
        plDevstaffId
        reviews {
          review
          rating
          reviewby
          recordId
          reviewbyId
          isCustomerReview
          isAdminStaffReview
        }
      }
      otherReviews: devstaffOutOfProjectReviews(week: $week, year: $year) {
        isPl
        isReviewRequired
        loggedHrs
        blockReason
        devstaffId
        devstaffName
        jiraProject
        jiraProjectName
        internalCategoryId
        role
        reviews {
          review
          rating
          reviewby
          recordId
          reviewbyId
          isCustomerReview
          isAdminStaffReview
        }
      }
    }
  `,
};
