import React, { Fragment, useLayoutEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import DetailsForm from 'components/details-form';
import ExpansionPanel from 'components/expansion-panel';
import Loader from 'components/loader';
import EmptyMessage from 'elements/empty-message';
import Wrapper from 'elements/wrapper';

const MultiFormsWrapper = ({
  body,
  isActiveControls,
  children,
  isRender,
  isVisible,
  isFetching,
  layoutEffect,
  layoutTriggers,
  wrapperCssRules,
  ...restProps
}) => {
  const hasContent = body.length;
  useLayoutEffect(layoutEffect, layoutTriggers);

  const content = useMemo(() => body.map(({ summaryConfig, formId, ...rest }) => {
    const form = (
      <DetailsForm
        isFetchingDetails={isFetching}
        formId={formId}
        {...restProps}
        {...rest}
      />
    );
    return (
      <Fragment
        key={formId}
      >
        {
          summaryConfig && !isFetching ? (
            <ExpansionPanel summaryConfig={summaryConfig}>
              {form}
            </ExpansionPanel>
          ) :
            form
        }
      </Fragment>
    );
  }), [body]);

  return (
    <Wrapper
      isGrow
      isVisible={isVisible}
      cssRules={wrapperCssRules}
    >
      {
        isFetching ?
          <Loader /> : (
            <>
              { children }
              { hasContent ?
                content : (
                  <EmptyMessage
                    className={wrapperCssRules}
                    description="Sorry, no content matched your criteria. Please, change the selected filters."
                  />
                )}
            </>
          )
      }
    </Wrapper>

  );
};

MultiFormsWrapper.propTypes = {
  wrapperCssRules: PropTypes.string,
  entityName: PropTypes.string,
  changeOrder: PropTypes.func,
  resetFilters: PropTypes.func,
  layoutEffect: PropTypes.func,
  changeFilter: PropTypes.func,
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  orderRules: PropTypes.shape({}),
  body: PropTypes.arrayOf(PropTypes.shape()),
  layoutTriggers: PropTypes.arrayOf(PropTypes.any),
  unitActions: PropTypes.shape({}),
  isActiveControls: PropTypes.bool,
  isRender: PropTypes.bool,
  children: PropTypes.node || PropTypes.arrayOf(PropTypes.node),
};

MultiFormsWrapper.defaultProps = {
  body: [],
  entityName: '',
  orderRules: {},
  isVisible: true,
  unitActions: {},
  isFetching: false,
  layoutTriggers: [],
  wrapperCssRules: '',
  isActiveControls: false,
  changeOrder: () => null,
  resetFilters: () => null,
  changeFilter: () => null,
  layoutEffect: () => null,
  isRender: true,
  children: null,
};

export default MultiFormsWrapper;
