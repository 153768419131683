import React, { useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import Loader from 'components/loader';
import ToastContainer from 'components/toast';
import { Formik, Form } from 'formik';
import { isEqual, isEmpty } from 'lodash';

import FormContent from './form-content';
import FormMainControls from './form-main-controls';
import FormUnit from './form-unit';
import { StyledMultiColumnWrapper, StyledForm } from './styled-components';
import TableForm from './table-form';

import './styles.scss';

const DetailsForm = ({
  clearModal,
  ptoModal,
  onSubmit,
  isVisible,
  isFormSubmitted,
  stylesTemplate: {
    gridTemplateAreas,
    gridColumnGap,
    withoutSpinner,
    formWrapperStyles,
    formStyles,
    cssRules,
    errorBoxCssRules,
    formControlsCss, // deprecated will be removed with adding in to form components a 'mainControlsConfig'
  },
  isFetchingDetails,
  content,
  changeOrder,
  orderRules,
  additionalControlsConfig,
  additionalActions: actions,
  withErrorBox,
  formId,
  onSubmitAction,
  additionalFields,
  mainControlsConfig,
  withoutFormControls,
  defaultControlsHidden,
  entityName,
  selectedName,
  remoteControl,
  buttonTitle,
  additionalComponents, // deprecated will be removed with adding in to form components a 'mainControlsConfig'
  hasPermission,
  additionalSubmit,
  children,
}) => {
  const { formData, validationRules, initialValues, tableData } = content;

  const onFormSubmit = ({ _serviceFields, ...formValues }) => {
    onSubmit({
      fields: formValues,
      initialValues,
      onSubmitAction,
      additionalFields,
    });
  };
  const formRef = useRef(null);
  const formContainerRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (remoteControl) {
      return remoteControl.registerForm({
        formId,
        formContainerRef,
        additionalFields,
        form: formRef.current,
      });
    }
  }, [formId]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationRules}
      onSubmit={onFormSubmit}
      ref={formRef}
      render={({
        values,
        errors,
        touched,
        handleReset,
        isSubmitting,
        setFieldValue,
        setFieldError,
        setFieldTouched,
      }) => {
        const isToastVisible = !isEmpty(errors) && withErrorBox;
        const hasFormChanges = !isEqual(initialValues, values);
        const form = {
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          hasFormChanges,
          withErrorBox,
          formId,
          actions,
        };
        if (remoteControl) {
          remoteControl.handleChanges({ formId, hasFormChanges });
        }

        if (remoteControl && 'revalidateToastIds' in remoteControl && !isEmpty(errors)) {
          remoteControl.revalidateToastIds(errors);
        }

        const handleError = ({ currentTarget }) => {
          const path = currentTarget.id;
          setFieldError(path, null);
        };

        return (
          <StyledForm
            ref={formContainerRef}
            className="details-form"
            cssRules={formWrapperStyles}
          >
            <Form className="details-form__form-component" autoComplete="off">
              <Loader
                isLoaded={withoutSpinner || !isFetchingDetails}
                cssRules={formStyles}
              >
                {tableData ? (
                  <TableForm
                    tableData={tableData}
                    changeOrder={changeOrder}
                    orderRules={orderRules}
                    entityName={entityName}
                    unitActions={actions}
                    form={form}
                    hasPermission={hasPermission}
                  />
                ) : (
                  <FormContent
                    gridTemplateAreas={gridTemplateAreas}
                    gridColumnGap={gridColumnGap}
                    cssRules={cssRules}
                    formData={formData}
                    selectedName={selectedName}
                    values={values}
                    setFieldValue={setFieldValue}
                    form={form}
                    isVisible={isVisible}
                    additionalComponents={additionalComponents}
                  />
                )}

                {isToastVisible && (
                  <ToastContainer
                    containerId={formId}
                    isHidden={isEmpty(errors)}
                    cssRules={errorBoxCssRules}
                    onClick={handleError}
                  />
                )}
                {!(
                  (defaultControlsHidden && !hasFormChanges) ||
                  withoutFormControls
                ) && (
                  <FormMainControls
                    clearModal={clearModal}
                    ptoModal={ptoModal}
                    hasFormChanges={hasFormChanges}
                    isFormSubmitted={isFormSubmitted && isSubmitting}
                    handleReset={handleReset}
                    actions={actions}
                    cssRules={formControlsCss} // deprecated will be removed with adding in to form components a 'mainControlsConfig'
                    buttonTitle={buttonTitle} // deprecated will be removed with adding in to form components a 'mainControlsConfig'
                    mainControlsConfig={mainControlsConfig}
                    additionalSubmit={additionalSubmit}
                    form={form}
                  />
                )}

                {additionalControlsConfig && (
                  <StyledMultiColumnWrapper
                    cssRules={additionalControlsConfig.cssRules}
                  >
                    {additionalControlsConfig.content.map((unit, index) => (
                      <FormUnit
                        key={index} // eslint-disable-line react/no-array-index-key
                        unit={unit}
                        index={index}
                        form={form}
                        isVisible={isVisible}
                      />
                    ))}
                  </StyledMultiColumnWrapper>
                )}
              </Loader>
            </Form>

            {children}
          </StyledForm>
        );
      }}
    />
  );
};

DetailsForm.propTypes = {
  clearModal: PropTypes.func,
  ptoModal: PropTypes.bool,
  remoteControl: PropTypes.any,
  selectedName: PropTypes.string,
  entityName: PropTypes.string,
  mainControlsConfig: PropTypes.shape({
    cssRules: PropTypes.string,
    acceptActionTitle: PropTypes.string,
    rejectActionTitle: PropTypes.string,
    onReject: PropTypes.func,
  }),
  onSubmitAction: PropTypes.string,
  additionalFields: PropTypes.shape({}),
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withErrorBox: PropTypes.bool,
  additionalActions: PropTypes.shape({}),
  additionalControlsConfig: PropTypes.shape({
    cssRules: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
  }),
  buttonTitle: PropTypes.string,
  defaultControlsHidden: PropTypes.bool,
  withoutFormControls: PropTypes.bool,
  orderRules: PropTypes.shape({}),
  changeOrder: PropTypes.func,
  isVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  isFormSubmitted: PropTypes.bool,
  stylesTemplate: PropTypes.shape({}),
  isFetchingDetails: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    formData: PropTypes.arrayOf(PropTypes.shape({})),
    initialValues: PropTypes.shape({}).isRequired,
    validationSchema: PropTypes.shape({}),
  }),
  hasPermission: PropTypes.bool,
  additionalSubmit: PropTypes.bool,
  children: PropTypes.node,
};

DetailsForm.defaultProps = {
  clearModal: () => null,
  ptoModal: false,
  formId: '',
  orderRules: {},
  entityName: '',
  isVisible: true,
  selectedName: '',
  onSubmitAction: '',
  withErrorBox: false,
  remoteControl: null,
  onSubmit: () => null,
  additionalFields: {},
  additionalActions: {},
  mainControlsConfig: {},
  isFormSubmitted: false,
  changeOrder: () => null,
  stylesTemplate: {
    gridTemplateAreas: '',
  },
  withoutFormControls: false,
  buttonTitle: 'Save changes',
  defaultControlsHidden: false,
  additionalControlsConfig: null,
  content: {
    formData: [],
    initialValues: {},
    validationSchema: {},
  },
  hasPermission: false,
  additionalSubmit: false,
  children: null,
};

export default DetailsForm;
