import {
  selectUserGroup,
  selectUserData,
  selectDevCentersBySystemRoleDD,
} from 'core/auth/selectors';
import { employeeDetailsModelGetter } from 'layouts/employee-details/model';
import { employeesListModelGetter } from 'layouts/employees-list/model';
import { projectsListModelGetter } from 'layouts/project-leads-management/model';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
  modelParser,
  parseDetails,
  parseFormData,
  filtersConfigGetter,
  filtersItemsGetter,
} from 'utils/helpers/models';
import { stringCompareFunction } from 'utils/helpers/sorting';

import { STORE_KEYS } from './constants';

const selectEmployeesData = (state) => state.delivery.employeesData;
const selectProjectsData = (state) => state.delivery.projectsData;
const selectPlList = (state) => state.delivery.plList;
export const selectDevCenters = (state) => state.delivery.employeeDetails.devcenters;
export const selectDevstaffId = (state) => state.delivery.employeeDetails.devstaffId;
export const selectEntityName = (state) => state.delivery.entityName;
export const selectFavoritesEmployees = (state) => state.delivery.favoritesEmployees;
export const selectFavoritesProjects = (state) => state.delivery.favoritesProjects;
export const selectOrderRules = (state) => state.delivery.orderRules;
export const selectIsFetchingEmployeesList = (state) => state.delivery.isFetching;
export const selectErrors = (state) => state.delivery.errors;
export const selectProjectDetails = (state) => state.delivery.projectDetails;
export const selectIsFiltering = (state) => state.delivery.isFiltering;
export const selectIsFormSubmitted = (state) => state.delivery.isFormSubmitted;
export const selectSelectedProject = (state) => state.delivery.selectedProject;
export const selectIsEmployeeEmailAvailable = (state) => state.delivery.isEmployeeEmailAvailable;
export const selectResourceBillingReport = (state) => state.delivery.resourceBillingReport;
export const selectEmployeesListModel = createSelector(
  selectUserGroup,
  selectResourceBillingReport,
  (group, resourceBillingReport) => employeesListModelGetter(group, resourceBillingReport)
);

const selectPlsData = createSelector(selectPlList, (devstaffList) => devstaffList
  .sort((a, b) => stringCompareFunction(a.fullname, b.fullname))
  .reduce(
    (acc, pl) => {
      const { map, plList, activeEmployees } = acc;
      const {
        plLevelByDate,
        isDismissed,
        devstaffId,
        isIntern,
        fullname,
        isNew,
        name,
      } = pl;
      if (!isDismissed && !isIntern && !isNew) {
        activeEmployees.push({
          label: fullname || name,
          value: devstaffId,
        });

        if (plLevelByDate && plLevelByDate.length) {
          plList.push({
            label: fullname || name,
            value: devstaffId,
          });
        }
      }

      return {
        activeEmployees,
        plList,
        map: {
          ...map,
          [devstaffId]: {
            label: fullname || name,
            value: devstaffId,
            ...pl,
          },
        },
      };
    },
    { list: [], map: {}, plList: [], activeEmployees: [] }
  ));
export const selectProjectListModel = createSelector(
  selectUserGroup,
  selectPlsData,
  (group, plsData) => projectsListModelGetter({ group, plsData })
);

export const selectProjectsCount = createSelector(
  selectProjectsData,
  (projects) => projects.length
);

export const selectFilters = (state) => state.delivery.filters;

export const selectFilterByAssignmentsValidPl = createSelector(
  selectFilters,
  (filters) => filters.projectsFilters[STORE_KEYS.IS_NO_VALID_PL]
);
export const selectFilterByAssignmentsPl = createSelector(
  selectFilters,
  (filters) => filters.projectsFilters[STORE_KEYS.IS_NO_PL]
);

const selectEmployeeFilterItems = createSelector(
  selectEmployeesData,
  selectEmployeesListModel,
  selectFilters,
  filtersItemsGetter
);

const selectProjectsFilterItems = createSelector(
  selectProjectsData,
  selectProjectListModel,
  selectFilters,
  filtersItemsGetter
);

export const selectEmployeeListFilterConfig = createSelector(
  selectFilters,
  selectEmployeeFilterItems,
  selectEmployeesListModel,
  ({ employeeFilters }, filterItems, options) => filtersConfigGetter(employeeFilters, filterItems, options)
);

export const selectProjectsListFilterConfig = createSelector(
  selectFilters,
  selectProjectsFilterItems,
  selectProjectListModel,
  ({ projectsFilters }, filterItems, options) => filtersConfigGetter(projectsFilters, filterItems, options)
);

const selectActiveFilters = createSelector(selectFilters, (filters) => Object.entries(filters).reduce((acc, [key, entity]) => {
  // eslint-disable-next-line no-unused-vars
  const activeFilters = Object.entries(entity).filter(([_, { isActive }]) => isActive);

  return {
    ...acc,
    [key]: {
      activeFilters,
      hasActiveFilters: !!activeFilters.length,
    },
  };
}, {}));

export const selectHasProjectsFilters = createSelector(
  selectActiveFilters,
  (filters) => get(filters, 'projectsFilters.hasActiveFilters', false)
);

export const selectHasEmployeeActiveFilters = createSelector(
  selectActiveFilters,
  (filters) => get(filters, 'employeeFilters.hasActiveFilters', false)
);

export const selectEmployeeActiveFilters = createSelector(
  selectActiveFilters,
  (filters) => get(filters, 'employeeFilters.activeFilters', false)
);

export const selectProjectsActiveFilters = createSelector(
  selectActiveFilters,
  (filters) => get(filters, 'projectsFilters.activeFilters', false)
);

export const selectedEmployeesData = createSelector(
  selectEmployeesData,
  selectFavoritesEmployees,
  selectOrderRules,
  selectEmployeeActiveFilters,
  selectEmployeesListModel,
  (
    dataList,
    favoritesList,
    orderRules,
    filters,
    { tableName, dataTemplate, rowStatusGetter, filtersTemplate }
  ) => {
    const options = {
      favorites: favoritesList,
      orderRules: orderRules[tableName],
      filters,
      idKey: 'devstaffId',
      tableName,
      rowStatusGetter,
      filtersTemplate,
    };
    const { data } = modelParser(dataList, dataTemplate, options);

    return data;
  }
);
export const selectedProjectsData = createSelector(
  selectProjectsData,
  selectFavoritesProjects,
  selectOrderRules,
  selectProjectsActiveFilters,
  selectProjectListModel,
  (
    dataList,
    favoritesProjects,
    orderRules,
    filters,
    { tableName, dataTemplate, rowStatusGetter, filtersTemplate }
  ) => {
    const options = {
      favorites: favoritesProjects,
      orderRules: orderRules[tableName],
      filters,
      idKey: 'internalCategoryId',
      tableName,
      rowStatusGetter,
      filtersTemplate,
    };
    const { data } = modelParser(dataList, dataTemplate, options);
    return data;
  }
);
export const selectParsedProjectDetails = createSelector(
  selectProjectDetails,
  ({ projectLeads = [] }) => ({
    projectLeads: projectLeads.map(({ storedPercentage, isPercentageCustomized, ...rest }) => ({
      ...rest,
      percentage: isPercentageCustomized ? storedPercentage : null,
    })),
  })
);

export const selectProjectForm = createSelector(
  selectSelectedProject,
  selectParsedProjectDetails,
  selectProjectListModel,
  (projectKey, projectDetails, { formTemplate: content }) => parseFormData({
    content,
    details: {
      projectKey,
      ...projectDetails,
    },
  })
);

export const selectEmployeesCount = createSelector(
  selectEmployeesData,
  (dataList) => (Array.isArray(dataList) ? dataList.length : 0)
);
export const selectIsFetchingDetails = (state) => state.delivery.isFetchingDetails;
export const selectCurrentEmployeeDetails = (state) => state.delivery.employeeDetails;
export const selectHolidays = createSelector(
  selectCurrentEmployeeDetails,
  (employeeDetails = {}) => {
    const { holidays = [] } = employeeDetails;

    return holidays.reduce((acc, { date, devcenter }) => {
      const { holidayDates, holydaysSet } = get(acc, devcenter, {
        holidayDates: [],
        holydaysSet: new Set(),
      });
      return {
        ...acc,
        [devcenter]: {
          holidayDates: [...holidayDates, new Date(date)],
          holydaysSet: holydaysSet.add(date),
        },
      };
    }, {});
  }
);

export const selectProjectsForPTO = createSelector(
  selectCurrentEmployeeDetails,
  (employeeDetails) => {
    const projects = get(employeeDetails, 'projects', []);
    const projectIdItems = [];
    const projectNameItems = [];
    const projectByKey = {};

    projects
      .sort((left, right) => stringCompareFunction(
        get(left, 'internalCategoryKey'),
        get(right, 'internalCategoryKey')
      ))
      .forEach(({ internalCategoryKey, internalCategoryName, internalCategoryLeadName }) => {
        projectNameItems.push({
          value: internalCategoryKey,
          label: internalCategoryName,
        });

        projectIdItems.push({
          value: internalCategoryKey,
          label: internalCategoryKey,
        });

        projectByKey[internalCategoryKey] = {
          internalCategoryName,
          internalCategoryLeadName,
        };
      });

    return {
      projectIdItems,
      projectNameItems,
      projectByKey,
    };
  }
);

export const selectEmployeeDetailsModel = createSelector(
  selectUserGroup,
  selectUserData,
  selectCurrentEmployeeDetails,
  selectProjectsForPTO,
  selectHolidays,
  selectDevCenters,
  selectDevCentersBySystemRoleDD,
  selectIsFormSubmitted,
  (
    userGroup,
    userData,
    employeeDetails,
    projectsForPTO,
    holidays,
    devcenters,
    devCentersBySystemRole,
    formSubmitted,
  ) => employeeDetailsModelGetter(
    userGroup,
    userData,
    employeeDetails,
    projectsForPTO,
    holidays,
    devcenters,
    devCentersBySystemRole,
    formSubmitted
  )
);
export const selectEmployeeDetails = createSelector(
  selectCurrentEmployeeDetails,
  selectOrderRules,
  selectEmployeeDetailsModel,
  (employeeDetails, orderRules, employeeDetailsModel) => parseDetails(employeeDetails, employeeDetailsModel, orderRules)
);
export const selectIsFavoredEmployee = createSelector(
  selectFavoritesEmployees,
  selectEmployeeDetails,
  (favorites, details) => details.devstaffId ? favorites[details.devstaffId] || false : false
);
