import React, { memo } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasicTable } from 'components/basic-table';
import ExpansionPanel from 'components/expansion-panel';

import styles from './style.module.scss';
import { useReportTable } from './useReportTable';

export const ReportTable = memo(({ label, data }) => {
  const { columns } = useReportTable(data);
  return (
    <ExpansionPanel
      summaryConfig={{
        groupId: 'requiredReviews',
        label,
        isDefaultExpanded: true,
      }}
    >
      <BasicTable
        id={label}
        columns={columns}
        className={classNames(styles.table)}
        data={data}
        paginate={{
          enable: false,
        }}
        isSort
      />
    </ExpansionPanel>
  );
});

ReportTable.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
