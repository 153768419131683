import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import {
  backgroundButtons,
  colorPrimary,
  colorSecondaryGrayLight,
  colorWhite,
  shadowButtons,
} from 'assets/styles/variables';
import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import GroupController from 'components/expansion-panel/group-control-button';
import FilterSelect from 'components/filter-select';
import Loader from 'components/loader';
import ToastContainer from 'components/toast';
import { USERS_GROUPS } from 'core/auth/constants';
import { CONTROL_KEYS } from 'core/employees-reviews/constants';
import Checkbox from 'elements/checkbox';
import GoTop from 'elements/go-top-button';
import RemoteControllerButton from 'elements/remote-controller-button';
import StyledWrapper from 'elements/styled-wrapper';
import WeekWithRange from 'elements/week-with-range';
import MultiFormsWrapper from 'layouts/multi-forms-wrapper';
import { get, set, cloneDeep } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { getHasPermissions } from 'utils/auth';
import { format } from 'utils/date';
import ExpandingSectionStore from 'utils/expanding-sections-store';

import { RemoteFormsController } from 'utils/helpers/forms';
import { autoScrollToTop } from 'utils/react';

const StyledFilter = styled.div`
  ${({ isRender }) => (isRender ? 'filter: blur(5px);' : '')}
`;

const { CPS } = USERS_GROUPS;

const EmployeesReviews = ({
  location,
  setDate,
  isFetching,
  entityName,
  orderRules,
  changeOrder,
  changeFilter,
  resetFilters,
  submitReviews,
  isFormSubmitted,
  notifyProjectLead,
  changeCurrentModal,
  reviewsManagementData,
  reviewsFormFilterOptions,
  controls,
  setControls,
  userGroup,
  date,
  isActiveControls,
}) => {
  autoScrollToTop(location);

  const isCPS = getHasPermissions(userGroup, [CPS]);

  const {
    body,
    onSubmit,
    wrapperCssRules,
    controlsCssRules,
    singleRowCssRules,
    monthPickerCssRules,
    filterSelectCssRules,
    styledWrapperCssRules,
    loaderWrapperCssRules,
    controlsWrapperCssRules,
  } = reviewsManagementData;

  const formsController = useMemo(() => new RemoteFormsController(({ formsState, controllerBag }) => onSubmit({
    controllerBag,
    formsState,
    changeCurrentModal,
    submitReviews,
  })));
  const [isRender, setIsRender] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [renderedBody, updateRenderedBody] = useState(body);
  const bodyMap = useMemo(
    () => body.reduce((acc, form) => ({ ...acc, [form.formId]: form }), {}),
    [body]
  );

  useEffect(() => {
    setDate(moment());
  }, []);

  useEffect(() => {
    updateRenderedBody(body);
  }, [body]);

  useEffect(() => {
    let updatedBody = selectedProjects.length ? selectedProjects.map(({ value }) => bodyMap[value]) : [...body];
    if (controls[CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]) {
      updatedBody = updatedBody.filter((obj) => {
        const data = get(obj, 'content.formData[0].data[0]');
        let values = get(data, 'fieldData.value', []);
        values = values.filter((value) => get(value, 'isReviewRequired', false) && !get(value, 'isBlocked', false));
        return get(data, 'name', '') !== '0' && values.length > 0;
      });
    }

    if (controls[CONTROL_KEYS.RATING_LESS_THAN_THREE]) {
      updatedBody = updatedBody.map((obj) => {
        const clonedObj = cloneDeep(obj);
        const value = get(clonedObj, 'content.formData[0].data[0].fieldData.value', []);
        const filteredValue = value.map((item) => {
          const clonedItem = { ...item, reviews: get(item, 'reviews', []).filter((review) => get(review, 'rating', null) !== null && get(review, 'rating', null) < 3) };
          return get(clonedItem, 'reviews', []).length > 0 ? clonedItem : null;
        }).filter((item) => item !== null);

        set(clonedObj, 'content.formData[0].data[0].fieldData.value', filteredValue);

        const initialValuesKeys = Object.keys(get(clonedObj, 'content.initialValues', {}));
        initialValuesKeys.forEach((key) => {
          if (filteredValue.length === 0) {
            set(clonedObj, `content.initialValues.${key}`, []);
          } else {
            set(clonedObj, `content.initialValues.${key}`, filteredValue);
          }
        });

        return clonedObj;
      }).filter((obj) => get(obj, 'content.formData[0].data[0].fieldData.value', []).length > 0);
    }
    updateRenderedBody(updatedBody);
  }, [selectedProjects, controls, body]);

  const setSelectedDate = (selectedDate) => {
    setSelectedProjects([]);
    setDate(selectedDate);
  };
  const onSelectProject = async (projects) => {
    setSelectedProjects(projects);
  };

  const handleControlClick = useCallback((controlsState) => () => {
    setControls(controlsState);
    if (isActiveControls) {
      ExpandingSectionStore.setGroupState({
        state: true,
        groupId: 'reviews',
      });
    }
  }, [isActiveControls]);

  const remoteControl = {
    registerForm: formsController.registerForm,
    handleChanges: formsController.handleChanges,
    revalidateToastIds: formsController.revalidateToastIds,
  };
  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    resetFilters,
    changeFilter,
    remoteControl,
    wrapperCssRules,
  };
  const actions = {
    onSelectProject,
    notifyProjectLead,
    stopSpinner: () => setIsRender(true),
  };

  return (
    <>
      <ToastContainer containerId={entityName} uniqueKey={format(date).toString()} />
      <Controls
        controlsHeading="Delivery Staff Reviews"
        cssRules={controlsCssRules}
        wrapperCssRules={controlsWrapperCssRules}
      >
        <StyledWrapper cssRules={styledWrapperCssRules}>
          <Calendar
            isWeekPicker
            withStepControls
            onChange={setSelectedDate}
            cssRules={monthPickerCssRules}
            stepControlsConfig={{
              step: 1,
              unitName: 'week',
            }}
          >
            <WeekWithRange />
          </Calendar>
          {reviewsFormFilterOptions.length > 1 ? (
            <FilterSelect
              isMultiple
              withChips={false}
              selected={selectedProjects}
              placeholderLength="12rem"
              placeholder="All categories"
              loadingPlaceholder={isRender ? 'Filtering' : 'Loading'}
              onChange={onSelectProject}
              isFetchingData={isFetching || isRender}
              cssRules={filterSelectCssRules}
              items={reviewsFormFilterOptions}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
            />
          ) : (
            <div />
          )}
          {!isCPS && (
            <RemoteControllerButton
              isFormSubmitted={isFormSubmitted}
              getControlsState={formsController.getControlsState}
              registerControl={formsController.registerControl}
              configGetter={({
                isFormSubmitted: isSubmitted = false,
                hasFormChanges = false,
                defaultConfig,
                currentConfig,
              }) => {
                const defaultCssRules = defaultConfig.cssRules;
                const activeCssRules = `
                ${defaultCssRules}
                border-color: ${isSubmitted ? 'transparent' : colorPrimary};
                color: ${colorWhite};
                background-color: ${isSubmitted ? 'transparent' : colorPrimary};
                pointer-events: all;
                opacity: 1;
                ${
            isSubmitted ?
              `
                  cursor: initial;
                ` :
              `
                  &:hover {
                    border-color: ${colorPrimary};
                    color: ${colorWhite};
                    background: ${backgroundButtons};
                    box-shadow: ${shadowButtons};
                  }
                `
            }
              `;

                return {
                  ...currentConfig,
                  cssRules:
                    hasFormChanges || isSubmitted ?
                      activeCssRules :
                      defaultCssRules,
                  onClick:
                    hasFormChanges && !isSubmitted ?
                      formsController.submitForms :
                      () => null,
                };
              }}
              defaultConfig={{
                title: 'Save changes',
                cssRules: `
                min-width: 16.8rem;
                max-height: 3.2rem;
                margin-top: 0.6rem;
                padding: 0.7rem 2.4rem;
                border-color: ${colorSecondaryGrayLight};
                color: #fff;
                background-color: ${colorSecondaryGrayLight};
                opacity: 0.6;
                pointer-events: none;
              `,
              }}
            />
          )}
          {!isFormSubmitted && !isCPS && (
            <RemoteControllerButton
              isFormSubmitted={isFormSubmitted}
              getControlsState={formsController.getControlsState}
              registerControl={formsController.registerControl}
              configGetter={({
                hasFormChanges = false,
                defaultConfig,
                currentConfig,
              }) => {
                const defaultCssRules = defaultConfig.cssRules;
                const activeCssRules = `
                    ${defaultCssRules}
                    border-color: ${colorPrimary};
                    color: ${colorPrimary};
                    pointer-events: all;
                    opacity: 1;
                  `;
                return {
                  ...currentConfig,
                  cssRules: hasFormChanges ? activeCssRules : defaultCssRules,
                  onClick: hasFormChanges ?
                    formsController.resetForm :
                    () => null,
                };
              }}
              defaultConfig={{
                title: 'Cancel',
                cssRules: `
                    min-width: 16.8rem;
                    max-height: 3.2rem;
                    margin-top: 0.6rem;
                    margin-left: 1.4rem;
                    padding: 0.7rem 2.4rem;
                    border-color: ${colorSecondaryGrayLight};
                    color: ${colorSecondaryGrayLight};
                    background-color: transparent;
                    opacity: 0.6;
                    pointer-events: none;
                  `,
              }}
            />
          )}
        </StyledWrapper>
      </Controls>
      {isRender && (
        <StyledWrapper cssRules={loaderWrapperCssRules}>
          <Loader />
        </StyledWrapper>
      )}
      <StyledFilter isRender={isRender}>
        <MultiFormsWrapper
          withErrorBox
          isActiveControls={isActiveControls}
          body={renderedBody}
          layoutEffect={() => {
            setIsRender(false);
          }}
          additionalActions={actions}
          layoutTriggers={[renderedBody]}
          {...layoutProps}
        >
          {!(isFetching) && (
            <StyledWrapper cssRules={singleRowCssRules}>
              <Checkbox
                title="Display only categories with reviews required"
                isActive={controls[CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]}
                onClick={handleControlClick({
                  [CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]: !controls[CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS],
                })}
              />
              <Checkbox
                title="Display only reviews with rating less than 3"
                isActive={controls[CONTROL_KEYS.RATING_LESS_THAN_THREE]}
                onClick={handleControlClick({
                  [CONTROL_KEYS.RATING_LESS_THAN_THREE]: !controls[CONTROL_KEYS.RATING_LESS_THAN_THREE],
                })}
              />
              {Boolean(renderedBody.length) && (
                <GroupController
                  groupId="reviews"
                  className="employees_reviews__group_control"
                />
              )}
            </StyledWrapper>
          )}
        </MultiFormsWrapper>
      </StyledFilter>
      <GoTop />
    </>
  );
};

EmployeesReviews.propTypes = {
  location: PropTypes.object.isRequired,
  tab: PropTypes.string,
  reportType: PropTypes.string,
  isFormSubmitted: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  changeOrder: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  orderRules: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  submitReviews: PropTypes.func.isRequired,
  notifyProjectLead: PropTypes.func.isRequired,
  changeCurrentModal: PropTypes.func.isRequired,
  reviewsManagementData: PropTypes.object.isRequired,
  reviewsFormFilterOptions: PropTypes.array.isRequired,
  userGroup: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  controls: PropTypes.shape({
    [CONTROL_KEYS.RATING_LESS_THAN_THREE]: PropTypes.bool,
    [CONTROL_KEYS.ONLY_CATEGORIES_WITH_REVIEWS]: PropTypes.bool,
  }).isRequired,
  setControls: PropTypes.func.isRequired,
  isActiveControls: PropTypes.bool,
};

EmployeesReviews.defaultProps = {
  tab: '',
  reportType: '',
  isFormSubmitted: false,
  isActiveControls: false,
};

export default withRouter(EmployeesReviews);
