import React, { useState, useEffect, memo } from 'react';

import PropTypes from 'prop-types';

import Select from 'components/select';
import { Field } from 'formik';
import { isEqual, get, isArray } from 'lodash';


const findSelectedOption = (formValue, { itemsMap, items }) => itemsMap ?
  itemsMap[formValue] :
  items.find(({ value }) => `${formValue}` === `${value}`);

const FormSelect = ({
  name,
  error,
  isMulti,
  touched,
  cssRules,
  isLocked,
  onChange,
  formValue,
  fieldData,
  withLabel,
  isTouched,
  placeholder,
  isCreatable,
  formValues,
  parentField,
  withErrorBox,
  menuPosition,
  validationRules,
  parentFieldsData,
  defaultMenuIsOpen,
  withTitle,
}) => {
  const [selectData, setSelectData] = useState(fieldData);
  useEffect(() => {
    const { itemsMap } = selectData;
    const selected = Array.isArray(formValue) ?
      formValue.map((item) => itemsMap[item]) :
      findSelectedOption(formValue, selectData) || {
        value: formValue,
        label: formValue,
      };
    setSelectData({
      ...selectData,
      selected,
      items: fieldData.items,
    });
  }, [formValue]);

  // TODO: refactor this isArray(parentFieldsData) ? parentFieldsData : [parentFieldsData] to something more correct

  useEffect(() => {
    if (parentField) {
      const { strictDependence } = parentField;
      const isSomeParentTouched = parentField.name.some((parentFieldName) => get(touched, parentFieldName));

      if (isSomeParentTouched || strictDependence) {
        const { valueGetter } = parentField;
        const calculatedValue = valueGetter(formValues, fieldData);

        if (calculatedValue && !isEqual(calculatedValue, selectData)) {
          const { selected = {} } = calculatedValue;
          const { value } = selected;

          setSelectData(calculatedValue);

          if (formValue !== value) {
            onChange(value);
          }
        }
      } else if (!isEqual(selectData, fieldData)) {
        setSelectData(fieldData);
      }
    }
  }, isArray(parentFieldsData) ? parentFieldsData : [parentFieldsData]);

  return (
    <Field
      type="text"
      name={name}
      render={() => (
        <Select
          name={name}
          error={error}
          isMulti={isMulti}
          onChange={onChange}
          isLocked={isLocked}
          cssRules={cssRules}
          withLabel={withLabel}
          withTitle={withTitle}
          fieldData={selectData}
          placeholder={placeholder}
          isCreatable={isCreatable}
          withErrorBox={withErrorBox}
          menuPosition={menuPosition}
          defaultMenuIsOpen={defaultMenuIsOpen}
          withError={!!(error && isTouched)}
          validationRules={validationRules}
          withWarning={fieldData.withWarning}
        />
      )
      }
    />
  );
};


FormSelect.propTypes = {
  isCreatable: PropTypes.bool,
  withErrorBox: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLocked: PropTypes.bool,
  validationRules: PropTypes.shape({
    isNumeric: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  formValue: PropTypes.any,
  touched: PropTypes.any,
  formValues: PropTypes.any,
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  parentFieldsData: PropTypes.array,
  fieldData: PropTypes.shape({
    isLink: PropTypes.bool,
    path: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ]),
  }),
  cssRules: PropTypes.string,

  parentField: PropTypes.shape({}),
  withLabel: PropTypes.bool,
  withTitle: PropTypes.bool,
  menuPosition: PropTypes.string,
  defaultMenuIsOpen: PropTypes.bool,
};

FormSelect.defaultProps = {
  isCreatable: false,
  withErrorBox: false,
  isMulti: false,
  touched: null,
  parentFieldsData: null,
  formValue: '',
  isLocked: false,
  placeholder: '',
  formValues: '',
  validationRules: {},
  fieldData: {
    selected: {
      value: '',
      label: '',
    },
    items: [],
  },
  onChange: () => null,
  error: '',
  isTouched: false,
  cssRules: '',
  parentField: null,
  withLabel: true,
  withTitle: false,
  menuPosition: 'absolute',
  defaultMenuIsOpen: false,
};

export default memo(FormSelect, (prevProps, currentProps) => {
  const { isTouched, formValue, error, disabled, parentFieldsData, fieldData, isLocked } = prevProps;
  if (isTouched !== currentProps.isTouched ||
    formValue !== currentProps.formValue ||
    error !== currentProps.error ||
    disabled !== currentProps.disabled ||
    fieldData.selected.value !== currentProps.fieldData.selected.value ||
    isLocked !== currentProps.isLocked ||
    parentFieldsData !== currentProps.parentFieldsData) {
    return false;
  }

  return true;
});
