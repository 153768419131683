import React, {useMemo, useState} from 'react';

import PropTypes from 'prop-types';

import ExpansionPanel from 'components/expansion-panel';
import StyledWrapper from 'elements/styled-wrapper';
import {FieldArray} from 'formik';
import hash from 'hash-sum';
import {get} from 'lodash';
import styled from 'styled-components';

import FieldArrayIntersectionContainer from './field-array-intersection-container';
import FieldArrayLine from './field-array-line';
import FormHeaders from './form-headers';
import FormUnit from './form-unit';

import {StyledTemplate} from './styled-components';

const StyledFlipMove = styled.div`
  box-sizing: border-box;
  display: grid;
  ${({ cssRules }) => cssRules && `${cssRules}`}
`;

const sortCurrentValues = ({ currentValues, orderConfig }) => {
  const { comparator, isReversed, orderBy } = orderConfig;
  return comparator && Array.isArray(currentValues) ?
    currentValues.sort((a, b) => comparator(a, b, isReversed, orderBy)) :
    currentValues;
};

const filterCurrentValues = ({ sortedValues, filterConfig }) => {
  const { filter, filterBy } = filterConfig;
  return filter(filterBy, sortedValues);
};

const FieldArrayUnit = ({
  name,
  form,
  isVisible,
  fieldData,
  validateOnChange,
  withFilter,
  intersectionContainer,
}) => {
  const { values, actions } = form;
  const currentValues = get(values, name);

  const {
    title,
    lineGetter,
    onPushLine,
    defaultLine,
    onDeleteLine,
    footerConfig,
    fieldArrayCss,
    headersConfig,
    noDataMessage,
    onUpdateFieldValue,
    stylesTemplate = {},
    defaultOrderRules = {},
    defaultFilterConfig = {},
  } = fieldData;

  const [orderConfig, setOrderConfig] = useState(defaultOrderRules);
  const [filterConfig, setFilterConfig] = useState(defaultFilterConfig);
  const changeOrder = (newOrderRules = {}) => setOrderConfig(({ isReversed }) => ({
    ...orderConfig,
    isReversed: !isReversed,
    ...newOrderRules,
  }));

  const changeFilter = (filterName, value) => setFilterConfig((prevFilter) => ({
    ...prevFilter,
    filterBy: {
      ...prevFilter.filterBy,
      [filterName]: value,
    },
  }));

  const clearFilter = () => setFilterConfig(defaultFilterConfig);

  const sortedValues = useMemo(() => sortCurrentValues({ currentValues, orderConfig }), [currentValues, orderConfig]);
  const filteredValues = withFilter ? filterCurrentValues({ sortedValues, filterConfig }) : sortedValues;

  return (
    <FieldArray
      name={name}
      validateOnChange={validateOnChange}
    >
      {
        ({ remove, move, replace, push, form: { setFieldError, setFieldTouched, setFieldValue } }) => {
          const footerStylesTemplate = get(footerConfig, 'stylesTemplate', {});
          const { cssRules: footerCssRules, ...footerTemplateProps } = footerStylesTemplate;
          const extendedActions = {
            ...actions,
            move,
            changeOrder,
            changeFilter,
            clearFilter,
            setFieldError,
            setFieldTouched,
            updateFieldValue: ({ fieldName, fieldValue, index }) => onUpdateFieldValue ?
              onUpdateFieldValue({
                index,
                fieldName,
                fieldValue,
                setFieldValue,
                setFieldError,
                setFieldTouched,
                values: filteredValues,
              }) :
              setFieldValue(fieldName, fieldValue),
            [`${name}_addLine`]: ({ index }) => {
              onPushLine({
                name,
                index,
                setFieldError,
                addLine: push,
                setFieldTouched,
                values: filteredValues,
              });
            },

            [`${name}_deleteLine`]: ({ index }) => (
              onDeleteLine ?
                onDeleteLine({
                  index,
                  addLine: push,
                  setFieldValue,
                  setFieldError,
                  removeLine: remove,
                  replaceLine: replace,
                  values: filteredValues,
                }) :
                remove(index)
            ),
          };
          const extendedForm = {
            ...form,
            name,
            actions: extendedActions,
          };
          const getLine = (item, multiItemIndex = 0) => {
            const {
              line,
              lineId,
              stylesTemplate: lineStylesTemplate = {},
              additionalLine = {},
            } = lineGetter({
              lineValues: item,
              index: multiItemIndex,
              actions: extendedActions,
              currentValues: filteredValues,
            });

            const { cssRules, ...otherProps } = lineStylesTemplate || {};
            const { withExpanding, data, stylesTemplate: additionalLineStylesTemplate } = additionalLine;
            const { contentWrapperCss } = additionalLineStylesTemplate || {};

            const rowContent = (
              <StyledTemplate
                key={hash(lineId)} // eslint-disable-line react/no-array-index-key
                className="details-form__field-array-line"
                stylesTemplate={otherProps}
                cssRules={cssRules}
              >
                {
                  line.map((unit, lineItemsIndex) => (

                    <FormUnit
                      key={hash(lineItemsIndex)} // eslint-disable-line react/no-array-index-key
                      unit={unit}
                      index={lineItemsIndex}
                      form={extendedForm}
                      isVisible={isVisible}
                    />
                  ))
                }
              </StyledTemplate>
            );
            const content = intersectionContainer ? <FieldArrayLine key={hash(lineId)}>{rowContent}</FieldArrayLine> : rowContent;
            return (
              withExpanding ? (
                <div
                  key={hash(lineId)} // eslint-disable-line react/no-array-index-key
                >
                  <ExpansionPanel
                    summaryContent={content}
                    summaryConfig={{
                      groupId: name,
                      cssRules: `
                        display: grid;
                        grid-template-columns: 32fr 1255fr;
                        padding-left: 0.8rem;
                        font-size: 1.6rem;

                        && .expansion-summary__control {
                          height: 4.5rem;
                        }
                    `,
                    }}
                  >
                    <StyledWrapper
                      cssRules={contentWrapperCss}
                    >
                      {
                        data.map((unit, lineItemsIndex) => (
                          <FormUnit
                            key={lineItemsIndex} // eslint-disable-line react/no-array-index-key
                            unit={unit}
                            index={lineItemsIndex}
                            form={extendedForm}
                            isVisible={isVisible}
                          />
                        ))
                      }
                    </StyledWrapper>
                  </ExpansionPanel>
                </div>
              ) :
                content
            );
          };
          const hasValues = filteredValues && filteredValues.length;

          return (
            <>
              {
                headersConfig && (
                  <FormHeaders
                    form={extendedForm}
                    orderRules={orderConfig}
                    filterRules={filterConfig}
                    headersConfig={headersConfig}
                    isVisible={isVisible}
                  />
                )
              }
              {
                hasValues || defaultLine ? (
                  <StyledFlipMove
                    className="details-form__field-array"
                    stylesTemplate={stylesTemplate}
                    cssRules={fieldArrayCss}
                    leaveAnimation="none"
                  >
                    {intersectionContainer && (
                      <FieldArrayIntersectionContainer matchingData={filteredValues.filter((item) => item.isMatchFilter).length}>
                        {filteredValues.map(getLine)}
                      </FieldArrayIntersectionContainer>
                    )}
                    {!intersectionContainer && (hasValues ? filteredValues.map(getLine) : getLine(defaultLine)) }
                  </StyledFlipMove>
                ) : (
                  <p className="details-form__field-array-line-placeholder">
                    {
                      noDataMessage ||
                      `No ${title} detail available`
                    }
                  </p>
                )
              }

              {
                footerConfig && (
                  <StyledTemplate
                    stylesTemplate={footerTemplateProps}
                    cssRules={footerCssRules}
                  >
                    {
                      footerConfig.data.map((footerUnit, footerItemIndex) => {
                        const { name: unitName, type } = footerUnit;
                        const { filterBy } = filterConfig;
                        let isActive = false;
                        let filterValue;

                        if (filterBy) {
                          filterValue = filterBy[unitName];
                          if (type === 'filter' && unitName !== 'clearFilter') {
                            isActive = !!filterBy[unitName].length;
                          }
                        }
                        return (
                          <FormUnit
                            key={footerItemIndex} // eslint-disable-line react/no-array-index-key
                            unit={
                              {
                                ...footerUnit,
                                isActive,
                                currentFilters: filterBy,
                                filterValue,
                              }
                            }
                            index={footerItemIndex}
                            title={title}
                            form={extendedForm}
                            isVisible={isVisible}
                          />
                        );
                      })
                    }
                  </StyledTemplate>
                )
              }

            </>
          );
        }
      }
    </FieldArray>
  );
};

FieldArrayUnit.propTypes = {
  isVisible: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  name: PropTypes.string.isRequired,
  fieldData: PropTypes.any.isRequired,
  form: PropTypes.shape({
    values: PropTypes.shape({}),
    actions: PropTypes.shape({}),
  }).isRequired,
  withFilter: PropTypes.bool,
  intersectionContainer: PropTypes.bool,
};

FieldArrayUnit.defaultProps = {
  isVisible: true,
  validateOnChange: true,
  withFilter: false,
  intersectionContainer: false,
};

export default FieldArrayUnit;
