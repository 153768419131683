import { createAction } from 'redux-actions';

import {
  createTypes,
  async,
} from 'utils/helpers/actions';

import { ENTITY_NAME } from './constants';

export const requiredReviewsReportTypes = createTypes([
  ...async('GET_REPORT'),
], ENTITY_NAME);

export const requiredReviewsReportActions = {
  getRequiredReviewsReport: createAction(requiredReviewsReportTypes.GET_REPORT),

  getRequiredReviewsReportSuccess: createAction(
    requiredReviewsReportTypes.GET_REPORT_SUCCESS,
    ({ data }) => ({ data })
  ),
  getRequiredReviewsReportFail: createAction(requiredReviewsReportTypes.GET_REPORT_FAIL, ({
    error = {},
  }) => ({
    error,
  })),
};
