import { waitForAuthorization } from 'core/auth/sagas';
import {
  get,
} from 'lodash';
import {
  put,
  takeLatest,
  select,
  call,
} from 'redux-saga/effects';
import {
  executeQuery, parseError,
} from 'utils/request';

import {
  requiredReviewsReportActions,
  requiredReviewsReportTypes,
} from './actions';
import { queries } from './queries';
import { selectEntityName, selectErrors } from './selectors';

function* getRequiredReviewsReport() {
  const errors = yield select(selectErrors);
  const storedErrors = get(errors, 'requiredReviewsReportError', []);
  const entityName = yield select(selectEntityName);
  const errorOptions = {
    entityName,
    storedErrors,
  };
  try {
    const query = queries.getRequiredReviewsReport;
    const options = {
      query,
    };
    const data = yield call(executeQuery, options, storedErrors);
    yield put(requiredReviewsReportActions.getRequiredReviewsReportSuccess({
      data: get(data, 'reviewsWidget', []),
    }));
  } catch (error) {
    const resourceManagementReportError = yield call(parseError, error, errorOptions);
    yield put(requiredReviewsReportActions.getRequiredReviewsReportFail({
      error: {
        resourceManagementReportError,
      },
    }));
  }
}

function* getRequiredReviewsWatcher() {
  yield takeLatest(requiredReviewsReportTypes.GET_REPORT, waitForAuthorization(getRequiredReviewsReport));
}
export default [
  getRequiredReviewsWatcher,
];
