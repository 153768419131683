import { formHeaderCss, actionButtonCss } from 'assets/styles/config';
import {
  colorPrimary,
  colorSecondaryText,
  colorSecondaryGray,
  colorSecondaryGrayLight,
  colorWhite,
} from 'assets/styles/variables';
import { get, groupBy } from 'lodash';
import moment from 'moment-timezone';

import { getUnixTime } from 'utils/helpers/date';
import { parseFormData } from 'utils/helpers/models';

import { managePtoConfigGetter } from './manage-pto-config';

moment.tz.setDefault('UTC');

export const additionalControlsCssRules = `
  display: block;
  grid-area: additional-controls;
  padding: 1.6rem;
  background-color: rgba(187,189,192, 0.3);
`;

export const listHeadingStyles = `
  z-index: 2;
  position: sticky;
  top: 0;
  grid-column-gap: 0.3rem;
  grid-row-gap: 0;
  margin-top: 0.4rem;
  padding: 0;
  background: #f2f2f2;
`;

export const descriptionCss = `
  box-sizing: border-box;
  display: inline-block;
  padding: 0.8rem 1.5rem 0 0;
  font-size: 1.2rem;
  line-height: 2.4rem;
  letter-spacing: -0.25px;
  color: rgb(0, 0, 0, 0.87);
`;

export const redButton = `
  background-color: #d61f26;
  color:#fff;
  width: 35px;
  height:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0px;
  line-height: 20px;
  padding: 0;
`;

export const infoCardCss = `
  display: grid;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid ${colorSecondaryGrayLight};
`;

export const secondaryButtonCss = `
  color: ${colorSecondaryGray};
  border-color: ${colorSecondaryGray};
  background-color: transparent;

  &:hover {
    color: ${colorWhite};
    border-color: ${colorPrimary};
    background-color: ${colorPrimary};
  }
`;

const statusMessageCss = `
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 0.7rem 0.5rem 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: -0.25px;
  text-align: center;
  color: rgb(0, 0, 0, 0.87);
  white-space: normal;
  overflow: hidden;
`;

export const textInputCss = `
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const datePickerCss = `
  align-items: center;
  max-height: unset;
  font-size: 1.4rem;
  letter-spacing: -0.0025rem;
  color: ${colorSecondaryText};
  && {
    min-width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
  }
`;

export const disabledPrimaryButtonCss = `
  background: #bbbdc0;
  border-color: #bbbdc0;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
`;

export const disabledSecondaryButtonCss = `
  border-color: #bbbdc0;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
`;

export const listHeadingDataGetter = ({
  withResourceName,
  withAction,
}) => (
  [{
    isHidden: !withResourceName,
    value: {
      title: 'Resource',
      cssRules: `
        ${formHeaderCss}
        text-align: left;
        padding-left: 1.6rem;
      `,
    },
  }, {
    value: {
      title: 'Request Date',
      cssRules: `
        ${formHeaderCss}
        text-align: left;
        ${withResourceName ? '' : 'padding-left: 1.6rem;'}
      `,
    },
  }, {
    value: {
      title: 'Description',
      cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
    },
  }, {
    value: {
      title: 'From',
      cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
    },
  }, {
    value: {
      title: 'Through',
      cssRules: `
        ${formHeaderCss}
        text-align: left;
      `,
    },
  }, {
    value: {
      title: 'Hrs',
      cssRules: `
        ${formHeaderCss}
        text-align: center;
      `,
    },
  }, {
    value: {
      title: 'Working day',
      cssRules: `
        ${formHeaderCss}
        line-height: 1.4rem;
        padding: 1rem 0 1.4rem;
        text-align: center;
      `,
    },
  }, {
    value: {
      title: 'Unpaid time\xa0-\xa0off',
      cssRules: `
        ${formHeaderCss}
        line-height: 1.4rem;
        padding: 1rem 0 1.4rem;
        text-align: center;
      `,
    },
  }, {
    isHidden: !withAction,
    value: {
      title: 'Action',
      cssRules: `
        ${formHeaderCss}
        text-align: center;
        padding-right: 0;
      `,
    },
  }, {
    value: {
      title: 'Approval Status',
      cssRules: `
        ${formHeaderCss}
        text-align: center;
        padding-right: 0;
      `,
    },
  }]
);

const approveButtonCSS = `
  ${actionButtonCss} && {
  align-self: flex-start;
  width: 100%;
  margin: 0;
  font-size: 1.3rem;}
`;

const rejectButtonCSS = `${actionButtonCss}
  && {
    align-self: flex-start;
    width: 100%;
    margin: 1.6rem 0 0;
    border-color: #d61f26;
    font-size: 1.3rem;
    color: #d61f26;
    background-color: transparent;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #d61f26;
    }
  }
`;

const rejectButtonLockedCSS = `
  ${actionButtonCss}
  && {
    align-self: flex-start;
    width: 100%;
    margin: 1.6rem 0 0;
    font-size: 1.3rem;
    color: #bbbdc0;
    background: transparent;
    border-color: #bbbdc0;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const approveButtonLockedCSS = `
  ${actionButtonCss}
  && {
    align-self: flex-start;
    width: 100%;
    margin: 0;
    font-size: 1.3rem;
    color: #fff;
    background: #bbbdc0;
    border-color: #bbbdc0;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const additionalControlsConfigGetter = ({
  isPlApprovalInProgress,
  hasPermissionsToUpdate,
  isDashboardRecords,
  isManagePtoRecord,
  isHistoryRecord,
  cancelledDate,
  rejectedDate,
  approvedDate,
  isCancelled,
  isTemplate,
  isApproved,
  isRejected,
  devstaffId,
  recordId,
  isDraft,
  isViewOnly,
  formSubmitted,
}) => {
  const isHiddenPendingApproval = isHistoryRecord || isManagePtoRecord || hasPermissionsToUpdate || isTemplate || isDraft || isRejected;
  const isHiddenPendingPlApproval = !isPlApprovalInProgress || isApproved || isCancelled;
  const isHiddenApproved = !isApproved || (isApproved && isCancelled);
  const isHiddenDisapproved = !isRejected || (isRejected && isCancelled);
  const isHiddenCanceled = !isCancelled;

  return {
    cssRules: additionalControlsCssRules,
    content: [
      {
        type: 'actionButton',
        data: 'Approve',
        withBody: true,
        isHidden: isViewOnly || isTemplate || isHistoryRecord || isManagePtoRecord || !hasPermissionsToUpdate,
        actionName: 'updatePtoRequestRecord',
        actionArguments: {
          fields: {
            isApproved: true,
            templateOwnerId: false,
          },
          recordId,
          devstaffId,
        },
        onFormChange: ({
          hasFormChanges,
        }) => hasFormChanges ? {
          cssRules: approveButtonLockedCSS,
        } : {},
        cssRules: formSubmitted ? approveButtonLockedCSS : approveButtonCSS,
      },
      {
        type: 'actionButton',
        data: 'Send to approve',
        withBody: true,
        isHidden: isViewOnly || isDashboardRecords || (!isDraft && !isRejected) || isTemplate || isHistoryRecord || isManagePtoRecord || hasPermissionsToUpdate,
        actionName: 'updatePtoRequestRecord',
        actionArguments: {
          fields: isRejected ? {
            isRejected: false,
          } : {
            templateOwnerId: false,
          },
          recordId,
          devstaffId,
        },
        onFormChange: ({
          hasFormChanges,
        }) => hasFormChanges ? {
          cssRules: approveButtonLockedCSS,
        } : {},
        cssRules: formSubmitted ? approveButtonLockedCSS : approveButtonCSS,
      },
      {
        type: 'actionButton',
        data: 'Disapprove',
        withBody: true,
        isHidden: isViewOnly || isTemplate || (isDraft && !hasPermissionsToUpdate) || isHistoryRecord || isManagePtoRecord || !hasPermissionsToUpdate || isRejected,
        actionName: 'updatePtoRequestRecord',
        actionArguments: {
          fields: {
            isRejected: true,
            templateOwnerId: false,
          },
          devstaffId,
          recordId,
        },
        onFormChange: ({
          hasFormChanges,
        }) => hasFormChanges || (isDraft && hasPermissionsToUpdate) ? {
          cssRules: rejectButtonLockedCSS,
        } : {},
        cssRules: ((isDraft && hasPermissionsToUpdate) || formSubmitted) ? rejectButtonLockedCSS : rejectButtonCSS,
      },
      {
        type: 'actionButton',
        data: 'Cancel',
        withBody: true,
        isHidden: isViewOnly || isTemplate || (isDraft && !hasPermissionsToUpdate) || isHistoryRecord || isManagePtoRecord || !hasPermissionsToUpdate,
        actionName: 'updatePtoRequestRecord',
        actionArguments: {
          fields: {
            isCancelled: true,
            templateOwnerId: false,
          },
          devstaffId,
          recordId,
        },
        onFormChange: ({
          hasFormChanges,
        }) => hasFormChanges ? {
          cssRules: rejectButtonLockedCSS,
        } : {},
        cssRules: formSubmitted ? rejectButtonLockedCSS : rejectButtonCSS,
      },
      {
        type: 'description',
        data: 'Not available',
        isHidden: Boolean(isHiddenPendingApproval && isHiddenPendingPlApproval && isHiddenApproved && isHiddenDisapproved && isHiddenCanceled) && isViewOnly ? false : !isTemplate,
        cssRules: `
      ${statusMessageCss}
      color: ${colorSecondaryGrayLight}
      font-size: 1.3rem;
    `,
      },
      {
        type: 'description',
        data: 'This\xa0record\xa0is\xa0in\xa0edit mode\xa0and\xa0doesn’t require\xa0approval\xa0yet',
        isHidden: isRejected || !isDraft || isDashboardRecords ? !isDraft : !hasPermissionsToUpdate,
        cssRules: `
      ${statusMessageCss}
      color: ${colorSecondaryGrayLight};
      line-height: 1.4rem;
    `,
      },
      {
        type: 'description',
        data: 'Pending approval',
        isHidden: isHiddenPendingApproval,
        cssRules: `
      ${statusMessageCss}
      color: ${colorPrimary};
      font-size: 1.3rem;
    `,
      },
      {
        type: 'description',
        data: 'Pending PL approval',
        isHidden: isHiddenPendingPlApproval,
        cssRules: `
      ${statusMessageCss}
      color: ${colorPrimary};
      font-size: 1.3rem;
    `,
      },
      {
        type: 'description',
        data: `Approved ${approvedDate ? moment(approvedDate).tz('America/New_York').format('L LT') : ''}`,
        isHidden: isHiddenApproved,
        cssRules: `
      ${statusMessageCss}
      padding: 0.7rem 4.5rem 0;
    `,
      },
      {
        type: 'description',
        data: `Disapproved ${rejectedDate ? moment(rejectedDate).tz('America/New_York').format('L LT') : ''}`,
        isHidden: isHiddenDisapproved,
        cssRules: `
      ${statusMessageCss}
      padding: 0.7rem 4.5rem 0;
      color: ${colorPrimary};
      line-height: 1.4rem;
    `,
      },
      {
        type: 'description',
        data: `Canceled ${cancelledDate ? moment(cancelledDate).tz('America/New_York').format('L LT') : ''}`,
        isHidden: isHiddenCanceled,
        cssRules: `
      ${statusMessageCss}
      padding: 0.7rem 4.5rem 0;
    `,
      },
    ],
  };
};

// PL approvals to show in history records
export const plApprovalsDataGetter = ({
  plApprovals = [],
}) => plApprovals.map((project) => {
  const {
    internalCategoryKey,
    internalCategoryName,
    internalCategoryLeadName,
    isApproved,
  } = project;

  return {
    type: 'text',
    data: `${internalCategoryKey} (${internalCategoryName}): ${internalCategoryLeadName || 'n/a'} - ${isApproved ? 'Yes' : 'No'}`,
    withBlank: true,
    cssRules: `
      ${descriptionCss}
      padding: 0 1.5rem 0 2.7rem;
      line-height: 2.7rem;
      letter-spacing: -0.025rem;
    `,
  };
});

export const getPtoData = ({
  ptoRequestRecords,
  vacations,
  isEmployed,
  employeeDetails,
  devstaffId,
}) => {
  const currentYear = moment().year();
  const recordsGroupedByYear = groupBy(ptoRequestRecords, (record = {}) => (
    !record.fromDate ?
      record.year || currentYear :
      moment(record.fromDate).year()
  ));
  const vacationsGroupedByYear = {};

  if (vacations) {
    vacations.forEach((yearData) => {
      const year = get(yearData, 'year');

      if (year) {
        vacationsGroupedByYear[year] = yearData;
        if (!recordsGroupedByYear[year] && isEmployed) { // add current year
          recordsGroupedByYear[year] = [];
        }
      }
    });
  }

  return Object.entries(recordsGroupedByYear).map(([year, records]) => {
    const hasRecords = !!records.length;

    const ptoRecords = hasRecords ? records.sort((left, right) => {
      if (!left) {
        return -1;
      }
      if (!right) {
        return 1;
      }
      const dateLeft = get(left, 'requestDate', moment(`${+year + 1}-12-25`));
      const dateRight = get(right, 'requestDate', moment(`${+year + 1}-12-25`));

      return getUnixTime(dateRight) - getUnixTime(dateLeft);
    }) : [null];

    const vacationData = get(vacationsGroupedByYear, `[${year}]`, {});

    const {
      vacationDays = {}, vacationActions,
    } = vacationData;
    const {
      remaining = 20,
    } = vacationDays;
    let managePtoModalParams = null;

    // if (Number(year) >= Number(currentYear)) {
    const usedYear = Number(year) >= Number(currentYear) ? currentYear : year;
    const vacationByYearId = get(vacationsGroupedByYear, `[${usedYear}].vacationByYearId`);
    const getYearType = () => {
      if (Number(year) > Number(currentYear)) {
        return 'next';
      }

      if (Number(year) < Number(currentYear)) {
        return 'prev';
      }

      return 'current';
    };
    const yearType = getYearType();

    const managePtoConfig = managePtoConfigGetter({
      remaining,
      yearType,
    });
    const managePtoFormContent = parseFormData({
      content: managePtoConfig.content,
      details: employeeDetails,
    });

    managePtoModalParams = {
      currentModal: 'MANAGE_PTO_MODAL',
      content: managePtoFormContent,
      stylesTemplate: managePtoConfig.stylesTemplate,
      vacationByYearId,
      devstaffId,
      year,
    };
    // }

    return {
      year,
      hasRecords,
      ptoRecords,
      modalParams: managePtoModalParams,
      vacationDaysByYear: vacationDays,
      managePTOActions: vacationActions || [],
    };
  }).sort((left, right) => get(right, 'year') - get(left, 'year'));
};
