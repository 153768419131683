import { waitForAuthorization } from 'core/auth/sagas';
import { ENTITY_NAME } from 'core/configurations/constants';
import { selectUserManagementRecordById } from 'core/configurations/user-management/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { takeLatest, call, select, put } from 'redux-saga/effects';

import { executeMutation, executeQuery, getErrorMessage } from 'utils/request';

import { actionTypes as userManagementTypes, userManagementActions } from './actions';
import { queryConfig } from './queries';

function* fetchUserRecords() {
  try {
    const query = queryConfig.fetchUserRecords;
    const options = {
      query,
    };

    const response = yield call(executeQuery, options);
    const records = get(response, 'userManagementData[0].userData', []);

    yield put(userManagementActions.fetchUserRecordsSuccess({
      userRecords: records,
    }));
  } catch (error) {
    toast.dismiss();
    toast.error(error.message, {
      containerId: ENTITY_NAME,
    });

    yield put(userManagementActions.fetchUserRecordsFail({
      error: {
        fetchUserRecordsError: error.message,
      },
    }));
  }
}

function* createUserRecord({ payload: {
  fields,
  userId,
} }) {
  try {
    const mutation = queryConfig.createUserRecord;
    const options = {
      mutation,
      variables: {
        fields,
      },
    };

    const response = yield call(executeMutation, options);
    const record = get(response, 'createUserRecord.userRecords[0].userData[0]', {});

    yield put(userManagementActions.createUserRecordSuccess(record));
  } catch (error) {
    toast.dismiss();

    const createUserRecordError = getErrorMessage(error);

    toast.error(createUserRecordError, {
      containerId: ENTITY_NAME,
    });

    yield put(userManagementActions.createUserRecordFail({
      error: {
        createUserRecordError,
      },
      userId,
    }));
  }
}

function* updateUserRecord({ payload: { userId, fields } }) {
  try {
    const currentEmail = get(fields, 'email', '');
    const { email } = yield select(selectUserManagementRecordById, userId);

    const mutation = queryConfig.updateUserRecord;
    const options = {
      mutation,
      variables: {
        fields,
        userId,
      },
    };

    const response = yield call(executeMutation, options);

    if (currentEmail && currentEmail !== email) {
      yield put(modalConductorActions.changeCurrentModal({
        currentModal: 'PREVIEW_MODAL',
        params: {
          content: [
            {
              type: 'title',
              data: 'Email Updated',
            },
            {
              type: 'description',
              data: 'Email has been updated, please select "Resend Invite" button and user will receive email for login into the system.',
            },
          ],
          parentFieldsData: [],
          withCloseButton: true,
          withConfirmButton: true,
        },
      }));
    }

    const record = get(response, 'updateUserRecord.userRecords[0].userData[0]', {});

    yield put(userManagementActions.updateUserRecordSuccess(record));
  } catch (error) {
    toast.dismiss();

    const updateUserRecordError = getErrorMessage(error);

    toast.error(updateUserRecordError, {
      containerId: ENTITY_NAME,
    });

    yield put(userManagementActions.updateUserRecordFail({
      error: {
        updateUserRecordError,
      },
      userId,
    }));
  }
}

function* deleteUserRecord({ payload: { userId } }) {
  try {
    const mutation = queryConfig.deleteUserRecord;
    const options = {
      mutation,
      variables: {
        userId,
      },
    };

    yield call(executeMutation, options);

    yield put(userManagementActions.deleteUserRecordSuccess({
      userId,
    }));
  } catch (error) {
    toast.dismiss();

    const deleteUserRecordError = getErrorMessage(error);

    toast.error(deleteUserRecordError, {
      containerId: ENTITY_NAME,
    });

    yield put(userManagementActions.deleteUserRecordFail({
      error: {
        deleteUserRecordError,
      },
    }));
  }
}

function* fetchUserRecordsWatcher() {
  yield takeLatest(userManagementTypes.FETCH_USER_RECORDS, waitForAuthorization(fetchUserRecords));
}

function* createUserRecordWatcher() {
  yield takeLatest(userManagementTypes.CREATE_USER_RECORD, waitForAuthorization(createUserRecord));
}

function* updateUserRecordWatcher() {
  yield takeLatest(userManagementTypes.UPDATE_USER_RECORD, waitForAuthorization(updateUserRecord));
}

function* deleteUserRecordWatcher() {
  yield takeLatest(userManagementTypes.DELETE_USER_RECORD, waitForAuthorization(deleteUserRecord));
}

export default [
  fetchUserRecordsWatcher,
  createUserRecordWatcher,
  updateUserRecordWatcher,
  deleteUserRecordWatcher,
];
